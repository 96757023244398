import React from 'react';
import {
  Avatar,
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Chip,
} from '@mui/material';

import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import MediaButton from './MediaButton';
import {Rust, Python} from './Languages'

function PersonalCard() {
  return (
    <Card sx={{ marginBottom: '16px' }}>
      <CardHeader
        avatar={<Avatar src={process.env.PUBLIC_URL + '/me.png'} />}
        title="Vladimir Alinsky"
        subheader="Software Engineering Lead"
      />

      <CardContent
        sx={{ padding: '0px 16px', ':last-child': { paddingBottom: '8px' } }}
      >
        <Typography sx={{ mb: 1.5 }}>
          Hello! I'm a Dreamer focusing on high-load distributed systems. I code
          in <Rust /> and <Python />, love close-to-science challenges and
          pancakes! This is my little personal website with media links and
          open-source projects.
        </Typography>
        <Typography sx={{ mb: 1.5 }}>Welcome ❤️</Typography>

        <Divider>
          <Typography color="text.secondary">Social Medias</Typography>
        </Divider>
        <Box sx={{ textAlign: 'center', paddingBottom: '0px' }}>
          <MediaButton
            text="LinkedIn"
            icon={<LinkedInIcon />}
            href="https://www.linkedin.com/in/alinsky/"
          />
          <MediaButton
            text="GitHub"
            icon={<GitHubIcon />}
            href="https://github.com/SKY-ALIN"
          />
          <MediaButton
            text="Instagram"
            icon={<InstagramIcon />}
            href="https://www.instagram.com/sky.alin/"
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default PersonalCard;
