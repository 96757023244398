import React from 'react';
import { Box, Chip } from '@mui/material';
import { SxProps, alpha } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

export interface LanguageChipProps {
  percent?: number;
}

interface BaseLanguageChipProps extends LanguageChipProps {
  label: string;
  backgroundColor?: string;
  textColor?: string;
  variant?: 'filled' | 'outlined';
  sx?: SxProps<Theme>;
}

export interface UnknownLanguageProps extends LanguageChipProps {
  title: string;
}

const LanguageChip: React.FC<BaseLanguageChipProps> = ({
  label,
  percent,
  backgroundColor,
  textColor,
  variant,
  sx,
}) => {
  const chipContent =
    percent !== undefined ? (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            pr: 1,
            borderRight: (theme) => {
              if (variant === 'outlined') {
                const color = textColor || theme.palette.text.primary;
                return `1px solid #616161`;
              }
              return '1px solid rgba(0, 0, 0, 0.12)';
            },
            fontWeight: 'bold',
          }}
        >
          {percent}%
        </Box>
        <Box sx={{ pl: 1, fontWeight: 'bold' }}>{label}</Box>
      </Box>
    ) : (
      <Box sx={{ fontWeight: 'bold' }}>{label}</Box>
    );

  return (
    <Chip
      label={chipContent}
      size="small"
      variant={variant || 'filled'}
      sx={{
        background: backgroundColor,
        color: textColor,
        fontWeight: 'bold',
        ...sx,
      }}
    />
  );
};

export const Rust: React.FC<LanguageChipProps> = ({ percent }) => {
  return (
    <LanguageChip
      label="Rust"
      percent={percent}
      backgroundColor="#dea584"
      textColor="#171A1C"
    />
  );
};

export const Python: React.FC<LanguageChipProps> = ({ percent }) => {
  return (
    <LanguageChip
      label="Python"
      percent={percent}
      backgroundColor="#3572A5"
    />
  );
};

export const WGSL: React.FC<LanguageChipProps> = ({ percent }) => {
  return (
    <LanguageChip
      label="WGSL"
      percent={percent}
      backgroundColor="transparent"
      sx={{
        background: `linear-gradient(45deg, #ff69b4, #8a2be2)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    />
  );
};

export const Others: React.FC<LanguageChipProps> = ({ percent }) => {
  return (
    <LanguageChip
      label="Others"
      percent={percent}
      textColor="rgba(255, 255, 255, 0.7)"
      variant="outlined"
    />
  );
};

export const UnknownLanguage: React.FC<UnknownLanguageProps> = ({ percent, title }) => {
  return (
    <LanguageChip
      label={title}
      percent={percent}
      backgroundColor="#000000"
    />
  );
};

export const languageComponentMap: { [key: string]: React.FC<{ percent?: number }> } = {
  Rust,
  Python,
  WGSL,
  Others,
};
